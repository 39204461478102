import { Component, Input } from '@angular/core';

import { HeaderComponent } from '@coreui/angular';
import { EventLogService, pageLinks } from '../../../core';
import { UserService } from './../../../features/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";

  protected newMessages = new Array(4)
  protected newTasks = new Array(5)
  protected newNotifications = new Array(5)

  constructor(
    protected userService: UserService,
    private eventLogService: EventLogService,
    private router: Router,
  ) {
    super();
  }

  protected async onLogOut() {
    const uid = this.userService.uid;
    await this.userService.logOut();
    this.eventLogService.insertSignOutEvent(uid);
    await this.router.navigate([pageLinks.login]);
  }
}
