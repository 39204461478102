import { DashboardSubscriptionTypesEnum, PaymentStatusesEnum, SubscriptionFailuresEnum } from '../enums'

export type CustomClaims = {
  cognicode?: string
  dashSubsType?: DashboardSubscriptionTypesEnum
  dashPayStatus?: PaymentStatusesEnum
  dashSubsFailure?: SubscriptionFailuresEnum | null
  dashSubsId?: string
}

export type CogniTeamCustomClaims = CustomClaims & {
  cogniAdmin?: boolean
  cogniSupport?: boolean
  cogniDev?: boolean
}