import { INavData } from '@coreui/angular';
import { CogniTeamRolesEnum } from '../../../libs';

export type CogniINavData = INavData & {
  requiredRole: CogniTeamRolesEnum
}

export const navItems: CogniINavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    requiredRole: CogniTeamRolesEnum.ADMIN
  },

  {
    title: true,
    name: 'Usuários',
    requiredRole: CogniTeamRolesEnum.ADMIN
  },
  {
    name: 'Consultar Usuários',
    url: '/users/search',
    iconComponent: { name: 'cil-people' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },
  {
    name: 'Apagar Usuários',
    url: '/users/delete',
    iconComponent: { name: 'cil-people' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },
  {
    name: 'Consultar EventLogs',
    url: '/users/eventlogs',
    iconComponent: { name: 'cil-list' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },

  {
    title: true,
    name: 'Assinaturas',
    requiredRole: CogniTeamRolesEnum.ADMIN
  },
  {
    name: 'Painel Clínico',
    url: '/billing/dash-subscriptions',
    iconComponent: { name: 'cil-clipboard' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Reembolsos',
        url: '/billing/dash-subscriptions/refunds'
      },
      {
        name: 'Em dia',
        url: '/billing/dash-subscriptions/ACTIVE/UP_TO_DATE'
      },
      {
        name: 'Atrasadas',
        url: '/billing/dash-subscriptions/ACTIVE/OVERDUE'
      },
      {
        name: 'Suspensas',
        url: '/billing/dash-subscriptions/ACTIVE/SUSPENDED'
      },
      {
        name: 'Bloqueadas',
        url: '/billing/dash-subscriptions/ACTIVE/BLOCKED'
      },
      {
        name: 'Novas ou Incompletas',
        url: '/billing/dash-subscriptions/INCOMPLETE/NONE'
      },
      {
        name: 'Canceladas',
        url: '/billing/dash-subscriptions/CANCELLED/NONE'
      },
      {
        name: 'Terminadas (Recentes)',
        url: '/billing/dash-subscriptions/TERMINATED_RECENT/NONE'
      },
      {
        name: 'Terminadas (Todas)',
        url: '/billing/dash-subscriptions/TERMINATED/NONE'
      },
      {
        name: 'Estudantes',
        url: '/billing/dash-subscriptions/special-subscriptions/students'
      },
    ]
  },
  {
    name: 'Matchmaking',
    url: '/billing/mm-subscriptions',
    iconComponent: { name: 'cil-address-book' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Reembolsos',
        url: '/billing/mm-subscriptions/refunds'
      },
      {
        name: 'Em dia',
        url: '/billing/mm-subscriptions/ACTIVE/UP_TO_DATE'
      },
      {
        name: 'Atrasadas',
        url: '/billing/mm-subscriptions/ACTIVE/OVERDUE'
      },
      {
        name: 'Suspensas',
        url: '/billing/mm-subscriptions/ACTIVE/SUSPENDED'
      },
      {
        name: 'Bloqueadas',
        url: '/billing/mm-subscriptions/ACTIVE/BLOCKED'
      },
      {
        name: 'Novas ou Incompletas',
        url: '/billing/mm-subscriptions/INCOMPLETE/NONE'
      },
      {
        name: 'Canceladas',
        url: '/billing/mm-subscriptions/CANCELLED/NONE'
      },
      {
        name: 'Terminadas',
        url: '/billing/mm-subscriptions/TERMINATED/NONE'
      },
    ]
  },
  {
    name: 'Procurar Assinaturas',
    url: '/billing/search-subscriptons',
    iconComponent: { name: 'cil-search' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },
  {
    name: 'Consultar Eventos',
    url: '/billing/events',
    iconComponent: { name: 'cil-spreadsheet' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },
  {
    name: 'Notas Pendentes',
    url: '/billing/pending-invoices',
    iconComponent: { name: 'cil-burn' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },
  /*{
    name: 'Assinantes',
    url: '/billing/subscribers',
    iconComponent: { name: 'cil-speedometer' },
    requiredRole: CogniTeamRolesEnum.ADMIN
  },*/

  {
    title: true,
    name: 'Matchmaking',
    requiredRole: CogniTeamRolesEnum.ADMIN
  },
  {
    name: 'Funnels',
    url: '/matchmaking/funnels',
    iconComponent: { name: 'cil-speedometer' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Video (UID)',
        url: '/matchmaking/funnels/playedVideoDistinctUid'
      },
      {
        name: 'Contatou (UID)',
        url: '/matchmaking/funnels/contactedProfessionalDistinctUid'
      },
      {
        name: 'Video (UID/PROID)',
        url: '/matchmaking/funnels/playedVideoDistinctUidProId'
      },
      {
        name: 'Contatou (UID/PROID)',
        url: '/matchmaking/funnels/contactedProfessionalDistinctUidProId'
      },
      {
        name: 'V/Cont (UID/PROID)',
        url: '/matchmaking/funnels/playedThenContacted'
      },
      {
        name: 'NoV/Cont (UID/PROID)',
        url: '/matchmaking/funnels/contactedWithoutPlaying'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/matchmaking/reports',
    iconComponent: { name: 'cil-speedometer' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Últimos 7 dias',
        url: '/matchmaking/reports/lastSevenDays'
      },
      {
        name: 'Últimos 30 dias',
        url: '/matchmaking/reports/lastThirtyDays'
      },
      {
        name: 'Desde 2024-01-01',
        url: '/matchmaking/reports/ever'
      },
    ]
  },
  {
    name: 'Surveys',
    url: '/matchmaking/surveys',
    iconComponent: { name: 'cil-spreadsheet' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
  },

  {
    title: true,
    name: 'Psychoeducation',
    requiredRole: CogniTeamRolesEnum.ADMIN
  },
  {
    name: 'Trials',
    url: '/psyedu/trials',
    iconComponent: { name: 'cil-applications-settings' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Liberar Trial',
        url: '/psyedu/trials/grant-trial',
      },
      {
        name: 'Em andamento',
        url: '/psyedu/trials/ACTIVE',
      },
      {
        name: 'Encerrados',
        url: '/psyedu/trials/TERMINATED',
      },
    ]
  },
  {
    name: 'Participantes',
    url: '/psyedu/participants',
    iconComponent: { name: 'cil-people' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Trials em andamento',
        url: '/psyedu/participants/TRIAL/ACTIVE',
      },
      {
        name: 'Trials encerrados',
        url: '/psyedu/participants/TRIAL/TERMINATED',
      },
    ]
  },

  {
    title: true,
    name: 'KPIs',
    requiredRole: CogniTeamRolesEnum.ADMIN
  },
  {
    name: 'Astella',
    url: '/kpis/astella',
    iconComponent: { name: 'cil-speedometer' },
    requiredRole: CogniTeamRolesEnum.ADMIN,
    children: [
      {
        name: 'Dash Mensal',
        url: '/kpis/astella/dash-monthly',
      },
      {
        name: 'Dash Anual',
        url: '/kpis/astella/dash-annual',
      },
      {
        name: 'Dash Anual (mês a mês)',
        url: '/kpis/astella/dash-annual-month-by-month',
      },
    ]
  },
  {
    name: 'WOW',
    url: '/kpis/wow/wow-default',
    iconComponent: { name: 'cil-speedometer' },
    requiredRole: CogniTeamRolesEnum.ADMIN
  },

  {
    title: true,
    name: 'Table Viewer',
    requiredRole: CogniTeamRolesEnum.ADMIN
  },
  {
    name: 'Visualizador',
    url: '/generic-table-viewer/viewer',
    iconComponent: { name: 'cil-columns' },
    requiredRole: CogniTeamRolesEnum.ADMIN
  },

  {
    title: true,
    name: 'Extras',
    requiredRole: CogniTeamRolesEnum.DEVELOPER
  },
  {
    name: 'Template',
    url: '/template/dashboard',
    iconComponent: { name: 'cibCoreuiC' },
    requiredRole: CogniTeamRolesEnum.DEVELOPER
  },
];
