import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './features/user/pages/login/login.component';
import { pageLinks } from './core';
import { AuthGuard, TeamGuard } from './features/user/guards';
import { TemplateLayoutComponent } from './template/containers'

const routes: Routes = [
  {
    path: 'action',
    loadChildren: () => import('./features/action/action-page.module').then(m => m.ActionPageModule),
  },
  {
    path: '',
    redirectTo: pageLinks.dashboard,
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: pageLinks.dashboard,
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'billing',
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/billing/billing.module').then((m) => m.BillingModule)
      },
      {
        path: 'matchmaking',
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/matchmaking/matchmaking.module').then((m) => m.MatchmakingModule)
      },
      {
        path: 'users',
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: pageLinks.psyedu,
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/psyedu/psyedu.module').then((m) => m.PsyeduModule)
      },
      {
        path: pageLinks.kpis,
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/kpis/kpis.module').then((m) => m.KPIsModule)
      },
      {
        path: pageLinks.genericTableViewer,
        canActivate: [TeamGuard],
        loadChildren: () =>
        import('./features/generic-table-viewer/generic-table-viewer.module').then((m) => m.GenericTableViewerModule)
      },
      {
        path: 'errors',
        loadChildren: () =>
        import('./features/errors/errors-pages.module').then((m) => m.ErrorsPagesModule)
      },
    ]
  },
  {
    path: 'template',
    component: TemplateLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Template'
    },
    children: [
      {
        path: pageLinks.dashboard,
        loadChildren: () =>
          import('./template/views/dashboard/dashboard.module').then((m) => m.TemplateDashboardModule)
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./template/views/theme/theme.module').then((m) => m.ThemeModule)
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./template/views/base/base.module').then((m) => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./template/views/buttons/buttons.module').then((m) => m.ButtonsModule)
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./template/views/forms/forms.module').then((m) => m.CoreUIFormsModule)
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./template/views/charts/charts.module').then((m) => m.ChartsModule)
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./template/views/icons/icons.module').then((m) => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./template/views/notifications/notifications.module').then((m) => m.NotificationsModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./template/views/pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./template/views/widgets/widgets.module').then((m) => m.WidgetsModule)
      }
    ]
  },
  {
    path: pageLinks.login,
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {path: '**', redirectTo: pageLinks.dashboard}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
