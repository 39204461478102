<!--<c-footer>-->
  <div>
    <a href="https://cogniapp.com" target="_blank">Cogni</a>
    <span> &copy; 2014-2024 Cogni</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/pro/angular" target="_blank">
      <span> CoreUI for Angular</span>
    </a>
  </div>
<!--</c-footer>-->
