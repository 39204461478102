import {
  GroupsEnum,
  LanguageCodesEnum,
  ProductsEnum,
  ShiftsEnum,
  PushPermissionStatusesEnum,
  ReminderPermissionStatusesEnum,
  PlatformsEnum,
  OperatingSystemsEnum,
  DeviceTypesEnum,
  InvitationSourcesEnum,
  AppNotificationTypesEnum,
  MatchmakingProfessionFiltersEnum,
  MatchmakingOrderingCriteriasEnum,
  EducationLevelCodesEnum,
  ProfessionCodesEnum,
  ProfessionalIdTypesEnum,
  MatchmakingSearchTypesEnum,
  MatchmakingSearchResultTypesEnum,
  GenderCodesEnum,
  MatchmakingApproachCodesEnum,
  MatchmakingTargetPublicCodesEnum,
  MatchmakingFocusCodesEnum,
  MatchmakingSpecialtyCodesEnum,
  AuthenticationProviderEnum,
} from '../..'

import {
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  SidesEnum,
  //CogniPageIdsEnum,
  ResultsEnum,
  DeletionReasonsEnum,
  EventPageIdsEnum
} from '.'

export type BaseEventLog = {
  agent: AgentsEnum
  event: EventsEnum
  group: GroupsEnum
  product: EventLogProductsEnum
  side: SidesEnum
  timestamp: Date
}

export type AccountCreatedEvent = BaseEventLog & {
  //affiliation: AffiliationCodesEnum - deprecated (still exist in eventlogs)
  email: string
  language: LanguageCodesEnum
  // the reason for signupSorce:
  // dash account could be created by traditional Dash or by the EMS abridged account creator
  signupSource: ProductsEnum
  authenticationProvider: AuthenticationProviderEnum
  uid: string
}

export type UserCreatedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  birthday?: string
  cognicode?: string
  proIdType?: ProfessionalIdTypesEnum
  proIdNumber?: string
  proIdState?: string
  educationLevel?: EducationLevelCodesEnum
  profession?: ProfessionCodesEnum
  graduationYear?: number
  otherProfession?: string
}

export type AppProfileCreatedEvent = BaseEventLog & {
  uid: string
}

export type DashboardProfileCreatedEvent = BaseEventLog & {
  uid: string
  cognicode: string
}

export type UserUpdatedEvent = BaseEventLog & {
  uid: string
  //affiliation?: AffiliationCodesEnum - deprecated (still exist in eventlogs)
  birthday?: string
  birthdayUpdated?: boolean
  cognicode?: string
  gender?: string
  language?: LanguageCodesEnum
  proIdType?: ProfessionalIdTypesEnum
  proIdNumber?: string
  proIdState?: string
  educationLevel?: EducationLevelCodesEnum
  profession?: ProfessionCodesEnum
  graduationYear?: number
  otherProfession?: string
}

export type AccountDeletedEvent = BaseEventLog & {
  uid: string
  email: string
  deletionReason: DeletionReasonsEnum
}

export type SignupAttemptedEvent = BaseEventLog & {
  email: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  result: ResultsEnum
  errorReason: string | null
  language: LanguageCodesEnum
  /**
   * empty string in case of failure
   */
  uid: string | null
}

export type SigninAttemptedEvent = BaseEventLog & {
  email: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  result: ResultsEnum
  errorReason: string | null
  /**
   * empty string in case of failure
   */
  uid: string | null
}

export type SignedOutEvent = BaseEventLog & {
  uid: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type AuthenticatedEvent = BaseEventLog & {
  uid: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  authenticationProvider: AuthenticationProviderEnum
}

export type PasswordRecoveryAttemptedEvent = BaseEventLog & {
  email: string
  result: ResultsEnum
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  errorReason: string | null
}

export type PasswordResetEvent = BaseEventLog & {
  email: string
  result: ResultsEnum
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type InterconnectionEvent = BaseEventLog & {
  patientId: string
  therapistId: string
  interconnectionId: string
}

export type RecordSharingEvent = BaseEventLog & {
  patientId: string
  therapistId: string
  interconnectionId: string
}

export type PatientArchivedEvent = BaseEventLog & {
  patientId: string
  therapistId: string
  interconnectionId: string
}

export type InvitationEvent = BaseEventLog & {
  patientId: string
  therapistId: string
  invitationId: string
  invitationSource: InvitationSourcesEnum
}

export type DTREvent = BaseEventLog & {
  uid: string
  [key: string]: unknown
}

// deprecated (still exist in eventlogs)
/*export type DepartmentAccessRequestEvent = BaseEventLog & {
  uid: string
  departmentId: string
  accessRequestId: string
  corporationId: string
}*/

// deprecated (still exist in eventlogs)
/*export type DepartmentInterconnectionEvent = BaseEventLog & {
  employeeId: string
  departmentId: string
  interconnectionId: string
  corporationId: string
}*/

export type MinicardClickedEvent = BaseEventLog & {
  uid: string
  id: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type ModuleOpenedEvent = BaseEventLog & {
  uid: string
  id: number
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type ModuleAccessAttemptedEvent = BaseEventLog & {
  uid: string
  id: number
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type ScaleOpenedEvent = BaseEventLog & {
  uid: string
  id: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type TrialGrantedEvent = BaseEventLog & {
  uid: string
}

export type PushConfigurationEvent = BaseEventLog & {
  uid: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  status: PushPermissionStatusesEnum
}

export type PushReceivedEvent = BaseEventLog & {
  uid: string
  id: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  type: AppNotificationTypesEnum
  signedIn: boolean
}

export type PushClickedEvent = BaseEventLog & {
  uid: string
  id: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  type: AppNotificationTypesEnum
  signedIn: boolean
}

export type ReminderConfigurationEvent = BaseEventLog & {
  uid: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  status: ReminderPermissionStatusesEnum
}

export type ReminderReceivedEvent = BaseEventLog & {
  uid: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  signedIn: boolean
  messageSet: string
  shift: ShiftsEnum
  messageIndex: number | null
}

export type ReminderClickedEvent = BaseEventLog & {
  uid: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  signedIn: boolean
  messageSet: string
  shift: ShiftsEnum
  messageIndex: number | null
}

export type MenuNotificationActionTakenEvent = BaseEventLog & {
  uid: string
  appNotificationType: AppNotificationTypesEnum
  notificationId: string
}

export type MenuNotificationIgnoredEvent = BaseEventLog & {
  uid: string
  appNotificationType: AppNotificationTypesEnum
  notificationId: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type HelpTipClickedEvent = BaseEventLog & {
  uid: string
  gifId: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type FaqClickedEvent = BaseEventLog & {
  uid: string | null
  email: string | null
  faqId: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type WhatsAppClickedEvent = BaseEventLog & {
  uid: string | null
  email: string
  ticketId: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type WhatsAppOpenedEvent = BaseEventLog & {
  uid: string | null
  email: string
  ticketId: string
  phoneNumber: string
  countryCode: string
  countryPt: string
  countryEn: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type SupportTicketSentEvent = BaseEventLog & {
  uid: string | null
  email: string
  phoneNumber: string | null
  ticketId: string
  language: LanguageCodesEnum
  deviceLanguage: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MenuHelpOthersClikedEvent = BaseEventLog & {
  uid: string
  language: LanguageCodesEnum
  deviceLanguage: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type ShareCogniCodeClikedEvent = BaseEventLog & {
  uid: string
  cognicode: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type GoToDashboardClikedEvent = BaseEventLog & {
  uid: string
  cognicode: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type GoToMatchmakingClikedEvent = BaseEventLog & {
  uid: string
  cognicode: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
  language: LanguageCodesEnum
  deviceLanguage: string
}

export type GoToPageClikedEvent = BaseEventLog & {
  pageId: EventPageIdsEnum
  uid: string
  language: LanguageCodesEnum
  deviceLanguage: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type UniqueDailyAuthenticatedEvent = BaseEventLog & {
  uid: null
}

export type ScheduleInterviewInvitationEvent = BaseEventLog & {
  uid: string
  option: number
}

export type MuralTileClikedEvent = BaseEventLog & {
  uid: string
  tileId: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type ContentAccessedEvent = BaseEventLog & {
  contentId: string
  uid: string
  language: LanguageCodesEnum
  deviceLanguage: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type PageAccessedEvent = BaseEventLog & {
  pageId: EventPageIdsEnum
  uid: string
  language: LanguageCodesEnum
  deviceLanguage: string
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingFiltersQuizSubmittedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingSearchRequestedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  keywords: string | null
  problem: string
  reason: string
  profession: MatchmakingProfessionFiltersEnum
  orderBy: MatchmakingOrderingCriteriasEnum[]
  searchId: string
  searchType: MatchmakingSearchTypesEnum
}

export type MatchmakingNextPageRequestedEvent = BaseEventLog & {
  uid: string
  searchId: string
  page: number
}

export type MatchmakingProfessionalSeenEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  professionalId: string
  searchId: string
  page: number
  position: number
  whatsAppAppMissing: boolean
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingVideoPlayedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  professionalId: string
  searchId: string
  page: number
  position: number
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingProfessionalClickedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  professionalId: string
  searchId: string
  page: number
  position: number
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingProfessionalContactedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  professionalId: string
  searchId: string
  page: number
  position: number
}

export type MatchmakingProfessionalFilteredEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  age: number
  language: LanguageCodesEnum
  problem: string
  reason: string
  profession: MatchmakingProfessionFiltersEnum
  orderBy: MatchmakingOrderingCriteriasEnum[]
  professionalId: string
  searchId: string
  searchType: MatchmakingSearchTypesEnum
  resultType: MatchmakingSearchResultTypesEnum
}

export type MatchmakingProfessionalListedEvent = BaseEventLog & {
  uid: string
  language: LanguageCodesEnum
  professionalId: string
  searchId: string
  page: number
  position: number
}

export type MatchmakingTeaserSeenEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  language: LanguageCodesEnum
  searchId: string
  teaserId: string
  position: number
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingTeaserClickedEvent = BaseEventLog & {
  uid: string
  gender: GenderCodesEnum
  language: LanguageCodesEnum
  searchId: string
  teaserId: string
  position: number
  deviceId: string | null
  deviceType: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string | null
  platform: PlatformsEnum
  appVersion: string | null
}

export type MatchmakingProfessionalAddedEvent = BaseEventLog & {
  uid: string
  professionalId: string
  approach: MatchmakingApproachCodesEnum
  targetPublic: MatchmakingTargetPublicCodesEnum
  focus: MatchmakingFocusCodesEnum
  specialty: MatchmakingSpecialtyCodesEnum | null
  sessionPrice: number
}

export type MatchmakingProfessionalChangedEvent = BaseEventLog & {
  uid: string
  professionalId: string
  approach: MatchmakingApproachCodesEnum
  targetPublic: MatchmakingTargetPublicCodesEnum
  focus: MatchmakingFocusCodesEnum
  specialty: MatchmakingSpecialtyCodesEnum | null
  sessionPrice: number
}

export type MatchmakingProfessionalRemovedEvent = BaseEventLog & {
  uid: string
  professionalId: string
}

export type EventLog =
  | SignupAttemptedEvent
  | SigninAttemptedEvent
  | SignedOutEvent
  | AccountCreatedEvent
  | AccountDeletedEvent
  | PasswordRecoveryAttemptedEvent
  | PasswordResetEvent
  | DTREvent
  | InterconnectionEvent
  | InvitationEvent
  | MinicardClickedEvent
  | ModuleOpenedEvent
  | ScaleOpenedEvent
  | PushConfigurationEvent
  | PushReceivedEvent
  | PushClickedEvent
  | ReminderConfigurationEvent
  | ReminderReceivedEvent
  | ReminderClickedEvent
  | HelpTipClickedEvent
  | FaqClickedEvent
  | WhatsAppClickedEvent
  | WhatsAppOpenedEvent
  | SupportTicketSentEvent
  | MenuHelpOthersClikedEvent
  | ShareCogniCodeClikedEvent
  | GoToDashboardClikedEvent
  | GoToPageClikedEvent
  | MenuNotificationActionTakenEvent
  | MenuNotificationIgnoredEvent
  | UniqueDailyAuthenticatedEvent
  | TrialGrantedEvent
  | ScheduleInterviewInvitationEvent
  | MuralTileClikedEvent
  | ContentAccessedEvent
  | PageAccessedEvent
  | MatchmakingFiltersQuizSubmittedEvent
  | MatchmakingSearchRequestedEvent
  | MatchmakingNextPageRequestedEvent
  | MatchmakingProfessionalSeenEvent
  | MatchmakingVideoPlayedEvent
  | MatchmakingProfessionalClickedEvent
  | MatchmakingProfessionalContactedEvent
  | MatchmakingProfessionalFilteredEvent
  | MatchmakingProfessionalListedEvent
  | MatchmakingProfessionalContactedEvent
  | MatchmakingTeaserSeenEvent
  | MatchmakingTeaserClickedEvent
  | MatchmakingProfessionalAddedEvent
  | MatchmakingProfessionalChangedEvent
  | MatchmakingProfessionalRemovedEvent
