export type TableHeader = {
  columnNames: string[]
}

export type TableRow = {
  columnValues: any[]
}

export type Table = {
  header: TableHeader
  rows: TableRow[]
}
