import { AbridgedGenderCodesEnum, ProfessionCodeNumbersEnum, ProfessionalIdTypesEnum } from '../enums'

export type TherapistCatalogEntry = {
  id: string
  cognicode: string
  firstName: string
  lastName: string
  gender: AbridgedGenderCodesEnum
  profession: ProfessionCodeNumbersEnum
  professionalId: string
  professionalIdType: ProfessionalIdTypesEnum | string
  verified: boolean
}
