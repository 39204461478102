import {
  AccountsEnum,
  PaymentMethodsEnum,
  OriginsEnum,
  ProductSubscriptionCodesEnum,
  SubscriptionRecurrenciesEnum,
  SubscriptionTransactionsEnum,
  CurrenciesEnum
} from '../enums'

export type TransactionDBDocument = {
  transactionId: string
  subscriptionId: string
  type: SubscriptionTransactionsEnum
  uid: string
  account: AccountsEnum
  currency: CurrenciesEnum | null
  origin: OriginsEnum
  payerCountry: string | null
  payerEmail: string | null
  payerName: string | null
  plan: string
  planPriceInCents: number | null
  product: ProductSubscriptionCodesEnum
  recurrence: SubscriptionRecurrenciesEnum
  transactionAtDate: Date
  feeInCents: number | null
  grossInCents: number | null
  paymentMethod: PaymentMethodsEnum | null
  esInvoiceNumber: string | null // electronic service invoice (NFS-e)
  referenceTransactionId: string | null
}

export type StripeTransactionDBDocument = TransactionDBDocument & {
  stripeCustomerId: string | null
  stripeProduct: string | null
  stripeEventId: string | null
  stripePaymentIntent: string | null
  stripeChargeId: string | null
  stripeInvoiceNumber: string | null
}
