import { BaseResponse, LanguageCodesEnum } from '../../..'
import { BaseRequest } from '.'

export type SendVerificationEmailRequest = BaseRequest & {
  languageCode: LanguageCodesEnum
}
export type SendVerificationEmailResponse = BaseResponse

export type CheckVerificationEmailRequest = BaseRequest & {
  code: string,
}

export type CheckVerificationEmailResponse = BaseResponse & {
  verified: boolean,
  canTryAgain: boolean,
}
