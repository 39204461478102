import { EmailProviderCodesEnum, GroupsEnum } from '..'

export type EmailEventDBDocument = {
  group: GroupsEnum
  email: string
  id: string
  emailTypeId: string
  emailTemplateId: string
  provider?: EmailProviderCodesEnum
  sendgridId?: string
  queuedAtDate?: Date
  sentAtDate?: Date
  deliveredAtDate?: Date
  openAtDate?: Date
  clickedAtDate?: Date
  deferredAtDate?: Date
  bouncedAtDate?: Date
  droppedAtDate?: Date
  reportedAtDate?: Date
  unsubscribedAtDate?: Date
  bounce_classification?: string
  type?: string
  reason?: string
  attempt?: number
  response?: string
  uid?: string
}
