import {
  BaseResponse,
  TherapistCatalogEntry,
  UserDBDocument,
} from '../..'
import { BaseRequest } from '.'

export type UserInformation = {
  authInfo: any | undefined
  user: UserDBDocument | undefined
  therapistCatalog: TherapistCatalogEntry | undefined
}

export type SuLoginRequest = BaseRequest & {
  email: string
}

export type SuLoginResponse = BaseResponse & {
  token: string
}

export type GetUserInformationRequest = BaseRequest & {
  email: string
}
export type GetUserInformationResponse = BaseResponse & UserInformation

export type GetUserInformationExRequest = BaseRequest & {
  input: string
}
export type GetUserInformationExResponse = BaseResponse & {
  results: UserInformation[]
}

export type DeleteUserRequest = BaseRequest & {
  email: string
}
export type DeleteUserResponse = BaseResponse
