import { Component } from '@angular/core';

import { navItems } from './_nav';

@Component({
  selector: 'app-template',
  templateUrl: './template-layout.component.html',
  styleUrls: ['./template-layout.component.scss'],
})
export class TemplateLayoutComponent {

  public navItems = navItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor() {}
}
