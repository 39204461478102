import {
  GenderCodesEnum,
  LanguageCodesEnum,
  SexCodesEnum,
  MobilePhoneNumber
} from '../..'

import {
  MatchmakingTermsVersionCodesEnum,
  PsyeduSubscriptionTypesEnum
} from '..'

export type AppUserView = {
  uid?: string
  email?: string
  language?: LanguageCodesEnum
  formatVersion?: number
  appOnboarding?: number
  appTermsVersionAccepted?: string
  mmTermsVersionAccepted?: MatchmakingTermsVersionCodesEnum
  privacyPolicyVersionAccepted?: string
  firstName?: string
  lastName?: string
  birthday?: string
  gender?: GenderCodesEnum
  appUpdatedAtDate?: Date
  minicardIds?: string[]
  cognicode?: string
  isTherapist?: boolean
  collegeStudent?: boolean
  sex?: SexCodesEnum
  psyeduSubscriptionType?: PsyeduSubscriptionTypesEnum
  mobilePhoneNumber?: MobilePhoneNumber
  isMatchmakingSubscriber?: boolean
}
