import { CogniTeamCustomClaims, CogniTeamRolesEnum, OpsUserView } from '../../../../libs';
import { UserRoles, UserIdentity, UserOptions } from '..';

export const OpsUserViewFactory = {

  createUserIdentity: (data: {
    uid: string,
    email: string,
    displayName: string,
  }): UserIdentity => ({
    uid: data.uid,
    email: data.email,
    firstName: data.displayName ? data.displayName.split('%%')[0] : null,
    lastName: data.displayName ? data.displayName.split('%%')[1] : null,
  }),

  createUserRoles: (data: {
    customClaims: CogniTeamCustomClaims
  }): UserRoles => {
    const roles :CogniTeamRolesEnum[] = []
    if (data?.customClaims?.cogniAdmin) {
      roles.push(CogniTeamRolesEnum.ADMIN)
    }
    if (data?.customClaims?.cogniSupport) {
      roles.push(CogniTeamRolesEnum.SUPPORT)
    }
    if (data?.customClaims?.cogniDev) {
      roles.push(CogniTeamRolesEnum.DEVELOPER)
    }
    return { roles }
  },

  reconstituteUserIdentity: (
    document: OpsUserView
  ): UserIdentity | undefined => {
    if (document) {
      return {
        uid: document.uid,
        email: document.email,
        firstName: document.firstName,
        lastName: document.lastName,
      };
    }
    return undefined;
  },

  reconstituteUserOptions: (
    document: OpsUserView
  ): UserOptions | undefined => {
    if (document) {
      return {
        gender: document.gender,
        language: document.language
      };
    }
    return undefined;
  }
};
