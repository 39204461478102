import { BaseResponse, LanguageCodesEnum } from '../../../../libs'
import { BaseRequest } from '.'

export type SendEmailVerificationActionLinkRequest = BaseRequest & {
  languageCode: LanguageCodesEnum
  url: string
}
export type SendEmailVerificationActionLinkResponse = BaseResponse

export type SendPasswordResetActionLinkRequest = BaseRequest & {
  languageCode: LanguageCodesEnum
  email: string
  url: string
}
export type SendPasswordResetActionLinkResponse = BaseResponse