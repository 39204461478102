import { BaseResponse, PrivateDashSettingsDBDocument, DashUserView, ProtectedDashSettingsDBDocument, AppUserView, PrivateAppSettingsDBDocument, ProtectedAppSettingsDBDocument, PublicAppSettingsDBDocument, PublicDashSettingsDBDocument} from '../..'
import { BaseRequest } from '.'

export type GetPublicLaunchContextRequest = BaseRequest

export type GetPublicLaunchContextResponse = BaseResponse & {
  publicSettings?: PublicAppSettingsDBDocument | PublicDashSettingsDBDocument
}

export type GetLaunchContextRequest = BaseRequest

export type GetAppLaunchContextResponse = BaseResponse & {
  user: AppUserView
  privateSettings?: PrivateAppSettingsDBDocument
  protectedSettings?: ProtectedAppSettingsDBDocument
}

export type GetDashLaunchContextResponse = BaseResponse & {
  user: DashUserView
  privateSettings?: PrivateDashSettingsDBDocument
  protectedSettings?: ProtectedDashSettingsDBDocument
}

export type GetLaunchContextResponse = GetAppLaunchContextResponse | GetDashLaunchContextResponse