import { BaseResponse, DeviceInfoDBDocument, LanguageCodesEnum } from '../../../../libs'
import { BaseRequest } from '.'

export type CreateAccountResponse = BaseResponse & {
  uid?: string
}

export type CreateAppAccountRequest = BaseRequest & {
  email: string
  password: string
  language: LanguageCodesEnum
  device: DeviceInfoDBDocument
  privacyPolicyVersionAccepted: string
  appTermsVersionAccepted: string
  continueUrl?: string // TODO remove - deprecated since version 2
  version: undefined | 2
}
export type CreateAppAccountResponse = CreateAccountResponse

export type CreateDashAccountRequest = BaseRequest & {
  email: string
  password: string
  language: LanguageCodesEnum
  device: DeviceInfoDBDocument
  privacyPolicyVersionAccepted: string
  dashTermsVersionAccepted: string
  continueUrl: string
}
export type CreateDashAccountResponse = CreateAccountResponse

export type CreateAccountRequest =
  | CreateAppAccountRequest
  | CreateDashAccountRequest
