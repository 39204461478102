import { SexCodesEnum } from '../..'

import {
  PsyeduSubscriptionTypesEnum,
  SubscriberTypesEnum,
  SubscriptionStatusesEnum,
  PsychoeducationEnrollmentStatusesEnum,
  ScaleSetsEnum,
  ModuleIds,
  ScaleIds,
} from '..'

export type PsychoeducationSubscriberDBDocument = {
  uid: string
  psychoeducationSubscriptionId: string
  subscriberType: SubscriberTypesEnum
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  enrollmentStatus: PsychoeducationEnrollmentStatusesEnum
  subscriptionStatus: SubscriptionStatusesEnum
  expiresAtDate?: Date
  pendingModules: ModuleIds
  lastUpdatedAtDate: Date
  birthday?: string
  sex?: SexCodesEnum
  collegeStudent?: boolean
  completedModules?: ModuleIds
  lastModuleCompleted?: number
  lastModuleCompletedAtDate?: Date
  practiceTimeExpiresAtDate?: Date
  scaleSet?: ScaleSetsEnum
  nextScales?: ScaleIds
  requireScaleBegginingAtDate?: Date
  lastScaleCompletedAtDate?: Date
}