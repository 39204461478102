<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="4">
        <c-card-group>
          <c-card class="p-4 frame">
            <c-card-body>
              <form [formGroup]="form">

                <div id="login-header">
                  <img src="/assets/favicon.png">
                  <h1>Cogni Ops</h1>
                </div>

                <c-input-group *ngIf="enableGoogle" class="mb-4">
                  <button cButton color="primary" variant="outline" class="px-4" (click)="onLogInWithGoogle()">
<svg aria-hidden="true" class="native svg-icon iconGoogle" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"></path></svg>
                     Entrar com Google
                  </button>
                </c-input-group>

                <hr *ngIf="enableGoogle">

                <c-input-group class="mb-3">
                  <input autoComplete="username"
                    cFormControl
                    placeholder="E-mail"
                    formControlName="email"
                  />
                </c-input-group>

                <c-input-group class="mb-4">
                  <input
                    autoComplete="current-password"
                    cFormControl
                    placeholder="Senha"
                    type="password"
                    formControlName="password"
                  />
                </c-input-group>

                <c-input-group class="mb-4">
                  <button cButton class="px-4" color="primary" [disabled]="form.invalid" (click)="onLogIn()">
                    Entrar
                  </button>
                </c-input-group>

                <c-row>
                  <c-col xs="4" lg="5" class="text-center">
                    <p *ngIf="accountNotFoundError"><code class="c-highlighter-rouge">conta não encontrada</code></p>
                    <p *ngIf="wrongPasswordError"><code class="c-highlighter-rouge">senha errada</code></p>
                    <p *ngIf="tooManyAttemptsError"><code class="c-highlighter-rouge">muitas tentativas, aguarde</code></p>
                    <p *ngIf="notAllowedError"><code class="c-highlighter-rouge">acesso não autorizado</code></p>
                  </c-col>
                  <!-- <c-col class="text-right" xs="6" lg="4"> -->
                  <!--   <button cButton class="px-0" color="link" disabled> -->
                  <!--     Esqueci minha senha? -->
                  <!--   </button> -->
                  <!-- </c-col> -->
                </c-row>

              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
