import { Component } from '@angular/core';
import { FooterComponent } from '@coreui/angular';

@Component({
  selector: 'app-template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.scss'],
})
export class TemplateFooterComponent extends FooterComponent {
  constructor() {
    super();
  }
}
