import { DashNoticesUponLaunchEnum } from '../enums'

export type PublicDashSettingsDBDocument = {
}

export type ProtectedDashSettingsDBDocument = {
  bannerOption: number
  showMhsNpsSurvey: boolean
}

export type PrivateDashSettingsDBDocument = {
  interviewOption?: number
  noticeUponLaunch?: DashNoticesUponLaunchEnum
  mhsNpsSurveyLastTakenAtDate?: string
  skipManifesto?: boolean
  skipMatchmakingTeaser?: boolean
  skipMatchmakingMock?: boolean
}
