import {
  PsyeduSubscriptionTypesEnum,
  SubscriberTypesEnum,
  SubscriptionStatusesEnum
} from '..'
import { PsychoeducationEnrollmentStatusesEnum, ScalesIdsEnum } from '../enums'

export type ModuleIds = number[]
export type ScaleIds = ScalesIdsEnum[]

export type IndividualPsychoeducationEnrollment = {
  uid: string
  psyeduSubscriptionId: string
  subscriberType: SubscriberTypesEnum
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  enrollmentStatus: PsychoeducationEnrollmentStatusesEnum
  subscriptionStatus: SubscriptionStatusesEnum
  expiresAtDate?: Date
  pendingModules: ModuleIds
  lastUpdatedAtDate: Date
}

export type PsychoeducationEnrollment = IndividualPsychoeducationEnrollment
