export type Situation = {
  index: number;
  situation: string | null
  automaticThought: string | null
  automaticThoughtMeaning: string | null
  emotion: string | null
  behavior: string | null
  format: number
}

export type ConceptualizationDBDocument = {
  id: string
  therapistId: string
  patientId: string
  createdAtDate: Date
  updatedAtDate: Date
  format: number
  relevantHistory: string | null
  coreBeliefs: string | null
  vision: string | null
  assumption: string | null
  strategies: string | null
  strengths: string | null
  situations: Situation[]
}

