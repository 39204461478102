import {
  GenderCodesEnum, InvitationSourcesEnum
} from '../..'

import {
  ProfessionalIdTypesEnum,
  ProfessionCodesEnum
} from '..'

export type InvitationDBDocument = {
  invitationId: string
  cognicode: string
  createdAtDate: Date
  updatedAtDate?: Date
  seenAtDate?: Date
  therapistId: string
  therapistFirstName: string
  therapistLastName: string
  therapistGender: GenderCodesEnum
  professionalId?: string
  professionalIdType?: ProfessionalIdTypesEnum
  profession: ProfessionCodesEnum
  patientId: string
  patientFirstName: string
  patientLastName: string
  patientGender: GenderCodesEnum
  invitationSource: InvitationSourcesEnum
}
