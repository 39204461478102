import { Component } from '@angular/core';

import { navItems } from './navigation';
import { UserService } from 'src/app/features/user';
import { INavData } from '@coreui/angular';
import { environment as env } from 'src/environments/environment';
import { CogniTeamRolesEnum } from '../../../libs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {

  protected buildInfo = `${env.message} ${env.buildInfo}`;
  protected navItems: INavData[];

  protected perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private userService: UserService) {
    if (this.userService.roles.includes(CogniTeamRolesEnum.ADMIN)) {
      this.navItems = navItems;
    }
    else {
      this.navItems = navItems.filter(item => this.userService.roles.includes(item.requiredRole));
    }
  }
}
