export type Thought = {
  id: string;
  dc: Date; // creation date
  du: Date; // update date
  f: number; // format
  c: string; // content
  i: boolean; // important
}

export type TherapistThought = Thought & {
  dd: Date | null // deletedAtDate
  o: boolean // outdated
}

export type TherapistThoughts = {
  [key: string]: TherapistThought
}

type commonDTRFields = {
  id: string // dtr id
  d: Date // date
  dc: Date // createdAtDate
  du: Date // updatedAtDate
  f: number // formatVersion
  m: number // mood level
  s: string | null // situation
  b: string | null // behavior
  eb: string[] | null // built-in emotions
  ec: string[] | null // custom emotions
  ab: string[] | null // built-in actions
  ac: string[] | null // custom actions
  rb: string[] | null // built-in reactions
  rc: string[] | null // custom reactions
}

export type DTRDBDocument = commonDTRFields & {
  uid: string // user id
  dd?: Date // deletedAtDate
  p: number // progress
  acc: string[] // custom action colors
  t: Thought[]
}

export type TherapistDTRDBDocument = commonDTRFields & {
  patientId: string
  therapistId: string
  dd: Date | null // deletedAtDate
  t: TherapistThoughts
}
