import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { isWithinInterval, isToday, format } from 'date-fns';
import { utcToZonedTime, format as tzFormat} from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private languageService: LanguageService) { }

  getRelativeDate(date: Date) {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate( sevenDaysAgo.getDate() - 6);
    if (isToday(date)) {
      return 'hoje';
    }
    else if (isWithinInterval (date, { start: sevenDaysAgo, end: new Date() })) {
      const dayOfTheWeek = format(date, 'eee', { locale: this.languageService.dateFns });
      return dayOfTheWeek.substring(0,3);
    }
    return format(date, this.languageService.shortDateFormat, { locale: this.languageService.dateFns });
  }

  getTime(date: Date) {
    return format(date, this.languageService.timeFormat, { locale: this.languageService.dateFns });
  }

  getPrepositionAccordingToLanguage(date: Date){
    if (date.getHours() < 2) {
      return 'à';
    } else {
      return 'às';
    }
  }

  getRelativeDateAndTime(date: Date) {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate( sevenDaysAgo.getDate() - 6);
    const aYearAgo = new Date();
    aYearAgo.setDate( aYearAgo.getDate() - 364);
    const time = this.getTime(date);
    const preposition = this.getPrepositionAccordingToLanguage(date);

    if (isToday(date)) {
      return `hoje ${preposition} ${time}`;
    }
    else if (isWithinInterval (date, { start: sevenDaysAgo, end: new Date() })) {
      const dayOfTheWeek = format(date, 'eeee', { locale: this.languageService.dateFns });
      return `${dayOfTheWeek} ${preposition} ${time}`;
    }
    else if (isWithinInterval (date, { start: aYearAgo, end: new Date() })) {
      const dayWithinYear = format(date, this.languageService.shortDateFormat, { locale: this.languageService.dateFns });
      return `${dayWithinYear} ${preposition} ${time}`;
    }
    const oldDate = format(date, this.languageService.longDateFormat, { locale: this.languageService.dateFns });
    return `${oldDate} ${preposition} ${time}`;
  }

  getDateAndTime(date: Date) {
    const time = this.getTime(date);
    const preposition = this.getPrepositionAccordingToLanguage(date);
    const formatedDate = format(date, this.languageService.longDateFormat, { locale: this.languageService.dateFns });
    return `${formatedDate} ${preposition} ${time}`;
  }

  get monthNames() {
    return this.languageService.monthNames;
  }

  get dayNamesForCalendar() {
    return this.languageService.dayNamesForCalendar;
  }

  getZonedDatetimeAsISOString(date: Date) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = utcToZonedTime(date, userTimeZone);
    return tzFormat(zonedTime, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', { timeZone: userTimeZone });
  }

  getZonedDatetime(date: Date, displayFormat: string) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = utcToZonedTime(date, userTimeZone);
    return tzFormat(zonedTime, displayFormat, { timeZone: userTimeZone,  locale: this.languageService.dateFns });
  }
}
