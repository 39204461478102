import { CogniTeamRolesEnum, GenderCodesEnum, LanguageCodesEnum } from '../../../../libs';

export type UserIdentity = {
  readonly uid: string | undefined;
  readonly email: string | undefined;
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
};

export type UserOptions = {
  readonly gender: GenderCodesEnum | undefined;
  readonly language: LanguageCodesEnum | undefined;
};

export type UserRoles = {
  readonly roles: CogniTeamRolesEnum[];
};

export type AppUserProfile = {
  readonly identity: UserIdentity | undefined;
  readonly options: UserOptions | undefined;
  readonly roles: UserRoles | undefined;
};
