import { DeviceInfo, LanguageCodesEnum, ProductsEnum } from '../..'

export type SupportTicketDBDocument = {
  id: string
  format: number
  createdAtDate: Date
  message: string
  source: ProductsEnum
  language: LanguageCodesEnum
  name: string
  email: string
  subjects: number[]
  device: DeviceInfo
  phoneNumber?: {
    internationalFormat: string
    countryPt: string
    countryEn: string
    countryCode: string
    verified: boolean
  }
  feedbackTab?: string
  updatedVersion: boolean
}
