import { BaseResponse } from '../../..'
import { BaseRequest } from '.'

export type SendVerificationSmsRequest = BaseRequest & {
  to: string,
  language: string,
}

export type CheckVerificationSmsRequest = BaseRequest & {
  code: string,
}

export type CheckVerificationSmsResponse = BaseResponse & {
  verified: boolean,
  canTryAgain: boolean,
}
