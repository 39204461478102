import { Injectable } from '@angular/core';
import {
  AuthClient,
  FirestoreClient,
  OpsEventLogFactory,
  OpsEventLog,
} from '../../../libs';
import { limit, orderBy, query, where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {

  private readonly systemId = 'system';

  constructor(private auth: AuthClient, private firestore: FirestoreClient) { }

  insertSignInAttemptEvent(email: string, errorReason?: string) {
    // if signin was successful, this.auth.uid is available
    const id =  this.getUidForEventLog(this.auth.uid);
    const eventlog = OpsEventLogFactory.createSignInAttemptEvent({
      email,
      uid: id,
      errorReason,
    });
    this.insertEventLog(id, eventlog);
  }

  insertSignOutEvent(uid: string) {
    // uid comes from the caller because the event is after signout
    const eventlog = OpsEventLogFactory.createSignOutEvent({
      uid,
    });
    this.insertEventLog(uid, eventlog);
  }

  insertAuthenticatedEvent() {
    const eventlog = OpsEventLogFactory.createAuthenticatedEvent({
      uid: this.auth.uid,
    });
    this.insertEventLog(this.auth.uid, eventlog);
  }

  insertPasswordRecoveryAttemptedEvent(email: string, errorReason?: string) {
    const id =  this.getUidForEventLog();
    const eventlog = OpsEventLogFactory.createPasswordRecoveryAttemptedEvent({
      email,
      errorReason,
    });
    this.insertEventLog(id, eventlog);
  }

  insertPasswordResetEvent(email: string) {
    const id =  this.getUidForEventLog();
    const eventlog = OpsEventLogFactory.createPasswordResetEvent({
      email,
    });
    this.insertEventLog(id, eventlog);
  }

  async queryEventLogsByUid(uid: string, eventCount: number) {
    const results: any[] = [];
    const qElogs = query(
      this.getEventLogsCollRef(uid),
      orderBy('timestamp', 'desc'),
      limit(eventCount)
    );
    const qTemplogs = query(
      this.getTempEventLogsCollRef(uid),
      orderBy('timestamp', 'desc'),
      limit(eventCount)
    );
    const elogs = await this.firestore.getColl(qElogs);
    Object.entries(elogs).map( (item: any) => {
      if (item) {
        const log = item[1].data()
        this.firestore.fromTimestampToDateByDocument(log, 'timestamp')
        results.push({ ...log, eventId: item[1].id})
      }
    });
    const templogs = await this.firestore.getColl(qTemplogs);
    Object.entries(templogs).map( (item: any) => {
      if (item) {
        const log = item[1].data()
        this.firestore.fromTimestampToDateByDocument(log, 'timestamp')
        results.push({ ...log, eventId: item[1].id})
      }
    });
    return results
  }

  async queryEventLogsByEmail(email: string, eventCount: number) {
    const qElogs = query(
      this.collGroupRef(this.elogsCollGroupId),
      where('email', '==', email),
      orderBy('timestamp', 'desc'),
      limit(eventCount)
    );
    const results: any[] = [];
    const elogs = await this.firestore.getColl(qElogs);
    Object.entries(elogs).map( (item: any) => {
      if (item) {
        const log = item[1].data()
        this.firestore.fromTimestampToDateByDocument(log, 'timestamp')
        results.push({ ...log, eventId: item[1].id})
      }
    });
    return results
  }

  async queryEventLogsByCognicode(cognicode: string, eventCount: number) {
    const qElogs = query(
      this.collGroupRef(this.elogsCollGroupId),
      where('cognicode', '==', cognicode),
      orderBy('timestamp', 'desc'),
      limit(eventCount)
    );
    const results: any[] = [];
    const elogs = await this.firestore.getColl(qElogs);
    Object.entries(elogs).map( (item: any) => {
      if (item) {
        const log = item[1].data()
        this.firestore.fromTimestampToDateByDocument(log, 'timestamp')
        results.push({ ...log, eventId: item[1].id})
      }
    });
    return results
  }

  // whenever the user might be unauthenticated, we need an alternative uid for the collection path, otherwise it will use .../undefined/...
  private getUidForEventLog(uid?: string) {
    return uid ?? this.systemId;
  }

  private insertEventLog(userId: string, attributes: OpsEventLog) {
    const evenlogId = this.getIdForNewEventLog(userId);
    this.firestore.insertDoc(this.getEventLogsCollRef(userId), evenlogId, attributes);
  }

  private getIdForNewEventLog(uid: string) {
    return this.firestore.newDocId(this.getEventLogsCollRef(uid))
  }

  private getEventLogsCollRef(uid: string) {
    return this.firestore.collRef(`${this.eventLogsCollId}/${uid}/${this.elogsCollGroupId}`)
  }

  private getTempEventLogsCollRef(uid: string) {
    return this.firestore.collRef(`${this.eventLogsCollId}/${uid}/${this.templogsCollGroupId}`)
  }

  private collGroupRef(colGroupId: string) {
    return this.firestore.collGroupRef(colGroupId)
  }

  private eventLogsCollId = '/eventLogs'
  // collection groups MUST NOT contain a leading '/'
  private elogsCollGroupId = 'elogs'
  private templogsCollGroupId = 'templogs'
}
