import {
  GenderCodesEnum,
  LanguageCodesEnum,
} from '../..'

export type OpsUserView = {
  uid?: string
  email?: string
  language?: LanguageCodesEnum
  firstName?: string
  lastName?: string
  gender?: GenderCodesEnum
}
