import { LanguageCodesEnum } from '../..'
import {
  AppOnboardingCodesEnum,
  MatchmakingTermsVersionCodesEnum,
  PsyeduSubscriptionTypesEnum
} from '..'

export type AccountDBDocument = {
  uid: string
  email: string
  createdAtDate: Date
  language: LanguageCodesEnum
  accountDeleted: boolean
  accountDeletedAtDate?: Date
  formatVersion: number
  appOnboarding: AppOnboardingCodesEnum
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  appTermsVersionAccepted?: string
  dashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  appTermsVersionAcceptedAtDate?: Date
  dashTermsVersionAcceptedAtDate?: Date
  mmTermsVersionAccepted?: MatchmakingTermsVersionCodesEnum
  mmTermsVersionAcceptedAtDate?: Date
  privacyPolicyVersionAcceptedAtDate?: Date
}
