import { SurveyTypesEnum } from '../enums'

export type MhsNpsSurvey = {
  mhs: number
  nps: number
  message?: string
}

export type SurveyDBDocument = {
  uid: string
  id: string
  createdAtDate: Date
  type: SurveyTypesEnum
  answers: MhsNpsSurvey
}
