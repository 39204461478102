export type PublicAppSettingsDBDocument = {
  whatsAppCogni: string
  VersionCompatibility: {
    minimumVersion: string
    nextMinimumVersion: string
    upgradeDeadlineAtDate: string
    androidAppLink: string
    iosAppLink: string
  }
}

export type ProtectedAppSettingsDBDocument = {
  matchmakingEnabled: boolean
  learnAboutMatchmakingUrl: string
}

export type PrivateAppSettingsDBDocument = {
  forceTherapistInfoUpdate: boolean
  matchmakingMainMenuIconBagdeDisabled: boolean
  matchmakingMainMenuNewBagdeDisabled: boolean
  matchmakingMainMenuDashboardBagdeDisabled: boolean
  seenCollapsedMainMenu: boolean
  hideLearnAboutMatchmakingTargetIcon: boolean
  hideFindTherapistTargetIcon: boolean
  hideMatchmakingPrimingFromMenu: boolean
}
