import {
  GenderCodesEnum,
  MobilePhoneNumber,
  SexCodesEnum
} from '../..'

import {
  AccountDBDocument,
  ProfessionalId,
  ProfessionalInformation,
} from '..'

export type UserDBDocument = AccountDBDocument & {
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  birthday?: string
  cognicode?: string
  isTherapist?: boolean,
  phoneNumber?: string, // TODO remove
  professionalInformation?: ProfessionalInformation
  professionalId?: ProfessionalId
  mobilePhoneNumber?: MobilePhoneNumber
  collegeStudent?: boolean
  sex?: SexCodesEnum
  updatedAtDate: Date
  lastSignedInAtDate: Date
  appUpdatedAtDate?: Date
  dashUpdatedAtDate?: Date
  appLastSignedInAtDate?: Date
  dashLastSignedInAtDate?: Date
}
