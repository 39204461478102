import { BaseResponse, LanguageCodesEnum, MatchmakingOrderingCriteriasEnum, MatchmakingProfessionFiltersEnum, MatchmakingProfessional } from '../..'
import { BaseRequest } from '.'

export type MatchmakingSearchCriteria = {
  /** @deprecated */
  name?: string | null
  keywords: string | null
  problem: string
  reason: string
  profession: MatchmakingProfessionFiltersEnum
  orderBy: MatchmakingOrderingCriteriasEnum[]
}

export type MatchmakingAppFormOptions = {
  problems: string[]
  problemCodes: string[]
  reasons: string[]
  reasonCodes: string[]
  professionCodes: string[]
  professions: string[]
}

export type SearchMatchmakingProfessionalsRequest = BaseRequest & {
  searchCriteria: MatchmakingSearchCriteria
  language: LanguageCodesEnum
}
export type SearchMatchmakingProfessionalsResponse = BaseResponse & {
  searchResultId: string
  results: MatchmakingProfessional[]
  baseMediaUrl: string
}

export type GetNextMatchmakingResultPageRequest = BaseRequest & {
  searchResultId: string
  pageNumber: number
  language: LanguageCodesEnum
}
export type GetNextMatchmakingResultPageResponse = BaseResponse & {
  searchResultId: string
  results: MatchmakingProfessional[]
  baseMediaUrl: string
}

export type GetAppFormOptionsRequest = BaseRequest & {
  language: LanguageCodesEnum
}
export type GetAppFormOptionsResponse = BaseResponse & {
  formOptions: MatchmakingAppFormOptions
}