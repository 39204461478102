import { Injectable } from '@angular/core';
import { SupportedLocale } from '../..';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private static _currentLanguage: SupportedLocale;

  constructor() { }

  get calendarDateFormat(): string {
    return LanguageService._currentLanguage.calendarDateFormat;
  }

  get shortDateFormat(): string {
    return LanguageService._currentLanguage.shortDateFormat;
  }

  get longDateFormat(): string {
    return LanguageService._currentLanguage.longDateFormat;
  }

  get timeFormat(): string {
    return LanguageService._currentLanguage.timeFormat;
  }

  get datetimeFormat(): string {
    return LanguageService._currentLanguage.datetimeFormat;
  }

  get dateFns() {
    return LanguageService._currentLanguage.dateFns;
  }

  get monthNames() {
    return LanguageService._currentLanguage.monthNames;
  }

  get monthShortNames() {
    return LanguageService._currentLanguage.monthShortNames;
  }

  get dayNamesForCalendar() {
    return LanguageService._currentLanguage.dayNamesForCalendar;
  }

  get onlyNumbersDateFormat() {
    return LanguageService._currentLanguage.onlyNumbersDateFormat;
  }

  get onlyNumbersDateCaption() {
    return LanguageService._currentLanguage.onlyNumbersDateCaption;
  }

  get onlyNumbersDatePlaceholder() {
    return LanguageService._currentLanguage.onlyNumbersDatePlaceholder;
  }
}
