import {
  CancellationReasonsEnum,
  PaymentStatusesEnum,
  ProductSubscriptionCodesEnum,
  PsyeduSubscriptionTypesEnum,
  SubscriberTypesEnum,
  SubscriptionFailuresEnum,
  SubscriptionStatusesEnum,
  TaxPayerInformation
} from '..'

export type BaseSubscription = {
  id: string
  startedAtDate: Date
  subscriberType: SubscriberTypesEnum
  status: SubscriptionStatusesEnum
  product: ProductSubscriptionCodesEnum
}

export type PsychoeducationSubscription = BaseSubscription & {
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  expiresAtDate?: Date
}

export type Subscription = PsychoeducationSubscription

export type SubscriptionUpdateType = {
  uid?: string
  payerCountry?: string
  payerEmail?: string
  subscriptionStatus?: SubscriptionStatusesEnum
  paymentStatus?: PaymentStatusesEnum
  subscriptionFailure?: SubscriptionFailuresEnum | null
  lastPaymentAtDate?: Date | null
  paymentDueAtDate?: Date | null
  cancelledAtDate?: Date | null
  expiresAtDate?: Date | null
  terminatedAtDate?: Date | null
  cancellationReason?: CancellationReasonsEnum | null
  taxPayerInformation?: TaxPayerInformation | null
  accumulatedValueInCents?: number
  paymentCycles?: number
  pendingInstallments?: number | null
  pendingESInvoce?: boolean
  pendingAudit: boolean
}

export type StripeSubscriptionUpdateType = SubscriptionUpdateType & {
  stripeNextPaymentAttemptAtDate?: Date | null
}
