import { SupportedLocale } from '..';
import { ptBR } from 'date-fns/locale';

export const supportedLocales: SupportedLocale[] = [
  {
    dateFns: ptBR,
    calendarDateFormat: 'dd/MM/yyyy',
    shortDateFormat: 'd-MMM',
    longDateFormat: 'dd-MMM-yyyy',
    timeFormat: 'HH:mm',
    datetimeFormat: 'dd-MMM-yyyy HH:mm',
    dayNamesForCalendar: ['D','S','T','Q','Q','S','S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthShortNames: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    onlyNumbersDateFormat: 'DMY',
    onlyNumbersDatePlaceholder: ['DD', 'MM', 'AAAA'],
    onlyNumbersDateCaption: ['Dia', 'Mês', 'Ano']
  }
];
