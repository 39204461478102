import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthClient } from '../../../../libs';
import { pageLinks } from '../../../core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthClient, private router: Router) { }

  async canActivate() {
    if (!this.auth.authStateReady) {
      return false;
    }
    if (this.auth.signedIn) {
      return true;
    }
    await this.router.navigate([pageLinks.login]);
    return false;
  }
}
