import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';
import { UserService } from './features/user';
import { AuthStatusesEnum } from 'src/libs';
import { EventLogService, pageLinks } from './core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'Cogni Ops';

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private userService: UserService,
    private eventLogService: EventLogService
  ) {
    this.titleService.setTitle(this.title);
    // iconSet singleton
    this.iconSetService.icons = { ...iconSubset };
    if (!location.hash.startsWith('#/action')) {
      this.userService.auth.authState$.subscribe( async (state) => {
        if (state !== AuthStatusesEnum.UNKNOWN) {
          this.initializeApp(state);
        }
      });
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }

  async initializeApp(state: AuthStatusesEnum) {
    if (state === AuthStatusesEnum.AUTHENTICATED) {
      this.eventLogService.insertAuthenticatedEvent();
      this.userService.setInitialUserState();
      await this.router.navigate([pageLinks.dashboard])
      await this.userService.loadUserProfile();
    } else if (state === AuthStatusesEnum.UNAUTHENTICATED) {
      await this.router.navigate([pageLinks.login])
    }
  }
}
