import { BaseResponse } from '../..'
import { BaseRequest } from '.'

export type GetHttpRedirectionRequest = BaseRequest & {
  url: string
}

export type GetHttpRedirectionResponse = BaseResponse & {
  urlRedirection?: string
}
