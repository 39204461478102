import { ProductsEnum } from '../libs';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  message: 'DEV Environment',
  buildInfo: '0c7526e 2024-10-17T19:49:10-03:00',
  domainConfigs: {
    productsEnum: ProductsEnum.OPS,
  },
  firebase: {
    apiKey: 'AIzaSyA9MHtCklUe8XVnwgP-M69msUkL84FRXSs',
    authDomain: 'cogni-ionic-dev.firebaseapp.com',
    databaseURL: 'https://cogni-ionic-dev.firebaseio.com',
    projectId: 'cogni-ionic-dev',
    storageBucket: 'cogni-ionic-dev.appspot.com',
    messagingSenderId: '1037140758256',
    appId: '1:1037140758256:web:ffb7320fced9acad9b7c35',
    measurementId: 'G-W2TK9YB4QT'
  },
  enablePersistenceForFirestore: false, // disabled for browser
  authWithGoogle: 'https://us-central1-cogni-ionic-dev.cloudfunctions.net/authWithGoogle',
  // authWithGoogle: 'http://127.0.0.1:5001/cogni-ionic-dev/us-central1/authWithGoogle',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
