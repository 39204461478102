import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { pageLinks } from '../../../core';
import { UserService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class TeamGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  async canActivate() {
    if (this.userService.isCogniTeamMember) {
      return true;
    }
    await this.userService.logOut();
    await this.router.navigate([pageLinks.login]);
    return false;
  }
}
