import { CurrenciesEnum, GenderCodesEnum, MatchmakingProfessionCodesEnum } from '../enums'

export type MatchmakingProfessional = {
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  professionalId: string
  profession: MatchmakingProfessionCodesEnum
  university: string
  graduationYear: number
  approach: string
  targetPublic: string
  focus: string
  specialty: string | null
  currency: CurrenciesEnum
  sessionPrice: number
  mediaFolder: string
  country: string
  whatsApp: string
  flags?: number
}
