import { Injectable } from '@angular/core';
import {
  ReadTableDataFromBigQueryRequest,
  ReadTableDataFromBigQueryResponse,
  ProductsEnum,
  OpsAuthenticatedRequestTypesEnum,
} from '../../../libs';
import { CloudFunctionsService } from '../firebase/cloud-functions.service';
import { Table, TableRow } from '.';

@Injectable({
  providedIn: 'root'
})
export class BigQueryService {

  constructor(private functionsService: CloudFunctionsService
  ) { }

  async getTable(query: string) {
    let table: Table;
    const result = await this.readTableDataFromBigQuery(query);
    const rows: any[] = JSON.parse(result);
    if (rows.length >= 1) {
      const headers = Object.keys(rows[0]);
      table = {
        header: {
          columnNames: headers
        },
        rows: []
      }
      for (const r in rows) {
        const row = rows[r];
        const tableRow: TableRow = {
          columnValues: []
        }
        for (const header in headers) {
          if (row[headers[header]]?.value) {
            tableRow.columnValues.push(row[headers[header]].value)
          }
          else {
            tableRow.columnValues.push(row[headers[header]])
          }
        }
        table.rows.push(tableRow);
      }
    }
    return table;
  }

  private async readTableDataFromBigQuery(query: string) {
    const request: ReadTableDataFromBigQueryRequest = {
      callerId: ProductsEnum.OPS,
      query
    };
    const result = await this.functionsService.opsAuthenticatedCall({
      type: OpsAuthenticatedRequestTypesEnum.READ_TABLE_DATA_FROM_BIGQUERY,
      request
    });
    return (result as ReadTableDataFromBigQueryResponse).results;
  }
}
