import { BaseResponse, InvitationDBDocument, PatientDBDocument } from '../..'
import { BaseRequest } from '.'

export type InviteTherapistRequest = BaseRequest & {
  cognicode: string
}
export type InviteTherapistResponse = BaseResponse & {
  invitation?: InvitationDBDocument
}

export type AcceptPatientInvitationRequest = BaseRequest & {
  invitationId: string
}
export type AcceptPatientInvitationResponse = BaseResponse & {
  patient: PatientDBDocument
}

export type RefusePatientInvitationRequest = BaseRequest & {
  invitationId: string
}
export type RefusePatientInvitationResponse = BaseResponse

export type CancelTherapistInvitationRequest = BaseRequest & {
  invitationId: string
}
export type CancelTherapistInvitationResponse = BaseResponse

export type StopSharingWithTherapistRequest = BaseRequest & {
  interconnectionId: string
}
export type StopSharingWithTherapistResponse = BaseResponse

export type ArchivePatientRequest = BaseRequest & {
  interconnectionId: string
}
export type ArchivePatientResponse = BaseResponse & {
  patient: PatientDBDocument
}

export type InvitePatientRequest = BaseRequest & {
  patientId: string
}
export type InvitePatientResponse = BaseResponse & {
  invitation?: InvitationDBDocument
  patient?: PatientDBDocument // TODO temporary workaround
}