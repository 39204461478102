import { BaseRequest } from '.'
import { BaseResponse } from '../..'

export type ReadTableDataFromBigQueryRequest = BaseRequest & {
  query: string
}
export type ReadTableDataFromBigQueryResponse = BaseResponse & {
  results: string
}

export type ExportBigQueryTableToRtdbRequest = BaseRequest & {
  query: string
  destinationPath: string
}
export type ExportBigQueryTableToRtdbResponse = BaseResponse