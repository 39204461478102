import {
  ResultsEnum,
  BaseEventLog,
} from '.'

export type OpsSigninAttemptedEvent = BaseEventLog & {
  email: string
  result: ResultsEnum
  errorReason: string | null
  /**
   * empty string in case of failure
   */
  uid: string | null
}

export type OpsSignedOutEvent = BaseEventLog & {
  uid: string
}

export type OpsAuthenticatedEvent = BaseEventLog & {
  uid: string
}

export type OpsPasswordRecoveryAttemptedEvent = BaseEventLog & {
  email: string
  result: ResultsEnum
  errorReason: string | null
}

export type OpsPasswordResetEvent = BaseEventLog & {
  email: string
  result: ResultsEnum
}

export type OpsEventLog =
  | OpsSigninAttemptedEvent
  | OpsSignedOutEvent
  | OpsAuthenticatedEvent
  | OpsPasswordRecoveryAttemptedEvent
  | OpsPasswordResetEvent
