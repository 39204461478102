import { EducationLevelCodesEnum, ProfessionalIdTypesEnum, ProfessionCodesEnum } from '..'
import { ActionLinkModesEnum, DeviceTypesEnum, OperatingSystemsEnum, PlatformsEnum, PushData, TaxPayerIdsEnum } from '..'

export type TaxPayerInformation = {
  taxId: string | null
  idType: TaxPayerIdsEnum | null
  payerName: string | null
  address: string | null
  city: string | null
  state: string | null
  country: string | null
  zipcode: string | null
  phone: string | null
}

export type MobilePhoneNumber = {
  internationalFormat: string
  countryCode: string
  verified: boolean
  nationalFormat?: string
}

export type DeviceInfo = {
  deviceUuid: string
  platform: PlatformsEnum
  type: DeviceTypesEnum
  os: OperatingSystemsEnum
  osVersion: string
  model: string
  languageCode: string
  appVersion?: string
  appBuild?: string
  browser?: string
  browserVersion?: string
}

export type Credential = {
  email: string
  password?: string
  rememberMe?: boolean
}

// oobCode is used by Firebase, and stands for out-of-band code
// since we use Action for both Firebase and Cogni's custom actions
// we'll use oobCode as the field name instead of otcCode
export type Action = {
  mode: ActionLinkModesEnum
  oobCode: string
  apiKey: string
  languageCode: string
  continueUrl?: string
  email?: string
}

export type CustomAction = Action & {
  protocol: 'http' | 'https'
  domain: string
  expiresAtDate: Date
  actionTaken: boolean
}

export type SignInAndVerifyAccountCustomAction = CustomAction & {
  encodedEmail: string
  encodedPassword: string
}

export type PushContentType = {
  notification: {
    title: string,
    body: string
  },
  data: PushData
}

export type BrazilCRM = {
  type: ProfessionalIdTypesEnum
  number: string
  state: string
}

export type ProfessionalId = BrazilCRM

export type ProfessionalInformation = {
  educationLevel: EducationLevelCodesEnum
  profession: ProfessionCodesEnum
  graduationYear?: number
  otherProfession?: string
}
