import {
  AppNotificationTypesEnum,
  AppNotificationStatusesEnum,
  ScaleSetsEnum
} from '../enums'
import { ScaleIds } from '../types'


export type AppBaseMenuNotificationDBDocument = {
  readonly uid: string
  readonly id: string
  readonly createdAtDate: Date
  readonly type: AppNotificationTypesEnum
  readonly status: AppNotificationStatusesEnum
  readonly seenAtDate?: Date
}

export type AppIdentifiedMenuNotificationDBDocument = AppBaseMenuNotificationDBDocument & {
  readonly name: string
  readonly itemId: string // record id, therapist id, cogni code...
  readonly link?: string
}

export type PsychoeductionModuleMenuNotificationDBDocument = AppBaseMenuNotificationDBDocument & {
  readonly title: string
  readonly moduleId: number
  readonly link: string
}

export type PsychoeductionScaleMenuNotificationDBDocument = AppBaseMenuNotificationDBDocument & {
  readonly scaleIds: ScaleIds
  readonly scaleSet: ScaleSetsEnum
  readonly link: string
}

export type AppCustomMenuNotificationDBDocument = AppBaseMenuNotificationDBDocument & {
  readonly title: string
  readonly line1: string
  readonly line2?: string
  readonly icon?: string
  readonly link?: string
  readonly buttonOk?: string;
}

export type AppMenuNotificationDBDocument = AppBaseMenuNotificationDBDocument
  | AppIdentifiedMenuNotificationDBDocument
  | PsychoeductionModuleMenuNotificationDBDocument
  | PsychoeductionScaleMenuNotificationDBDocument
  | AppCustomMenuNotificationDBDocument

export type AppMenuNotificationSeenStatusUpdate = {
  readonly status: AppNotificationStatusesEnum
  readonly seenAtDate: Date
}

export type AppMenuNotificationActionPendingStatusUpdate = {
  readonly status: AppNotificationStatusesEnum
  readonly seenAtDate: Date
}

export type AppMenuNotificationTerminatedStatusUpdate = {
  readonly status: AppNotificationStatusesEnum
  readonly terminatedAtDate: Date
}

export type AppMenuNotificationStatusUpdate = AppMenuNotificationSeenStatusUpdate
  | AppMenuNotificationActionPendingStatusUpdate
  | AppMenuNotificationTerminatedStatusUpdate
