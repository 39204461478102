import { ProductsEnum } from '../..'
import {
  AppNotificationTypesEnum,
} from '../enums'

export type BaseNotification = {
  readonly type: AppNotificationTypesEnum
  readonly uid: string
  readonly notificationId: string
  readonly target: ProductsEnum
}
