import {
  GenderCodesEnum,
  LanguageCodesEnum,
  MobilePhoneNumber
} from '../..'

import {
  ProfessionalId,
  ProfessionalInformation,
} from '..'

export type DashUserView = {
  uid?: string
  email?: string
  language?: LanguageCodesEnum
  dashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  cognicode?: string
  gender?: GenderCodesEnum
  firstName?: string
  lastName?: string
  mobilePhoneNumber?: MobilePhoneNumber
  professionalInformation?: ProfessionalInformation
  professionalId?: ProfessionalId
}
