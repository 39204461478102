import { ActionLinkModesEnum, BaseResponse } from '../../../../libs'
import { BaseRequest } from './base-request.type'

export type ValidateCustomActionLinkRequest = BaseRequest & {
  mode: ActionLinkModesEnum
  oobCode: string
  apiKey: string
}

export type ValidateCustomActionLinkResponse = BaseResponse & {
  [key: string]: string
}

export type GenerateDashPasswordlessSigninLinkRequest = BaseRequest

export type GenerateDashPasswordlessSigninLinkResponse = BaseResponse & {
  link?: string
}
