import { Injectable } from '@angular/core'
import { OpsAuthenticatedCFRequest, CloudFunctionsClient, DatabaseResponse, UnauthenticatedCFRequest } from '../../../libs'

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {

  constructor(private functionsClient: CloudFunctionsClient) { }

  async opsAuthenticatedCall(
    request: OpsAuthenticatedCFRequest
  ): Promise<DatabaseResponse> {
    return this.functionsClient.call('handleOpsAuthenticatedRequest', request)
  }

  async unauthenticatedCall(
    request: UnauthenticatedCFRequest
  ): Promise<DatabaseResponse> {
    return this.functionsClient.call('handleUnauthenticatedRequest', request)
  }
}
