import { MatchmakingApproachCodesEnum, MatchmakingFocusCodesEnum, MatchmakingSpecialtyCodesEnum, MatchmakingTargetPublicCodesEnum } from '../enums'
import { CurrenciesEnum, GenderCodesEnum, MatchmakingProfessionCodesEnum } from '../enums'

export type MatchmakingProfessionalTypesense = {
  id: string
  email: string
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  professionalIdType: string
  professionalId: string
  profession: MatchmakingProfessionCodesEnum
  university: string
  graduationYear: number
  approach: MatchmakingApproachCodesEnum
  targetPublic: MatchmakingTargetPublicCodesEnum
  focus: MatchmakingFocusCodesEnum
  specialty: MatchmakingSpecialtyCodesEnum | null
  currency: CurrenciesEnum
  sessionPrice: number
  mediaFolder: string
  whatsApp: string
  country: string
}

export type MatchmakingProfessionalDBDocument = {
  uid: string
  email: string
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  professionalIdType: string
  professionalId: string
  profession: MatchmakingProfessionCodesEnum
  university: string
  graduationYear: number
  approach: MatchmakingApproachCodesEnum
  targetPublic: MatchmakingTargetPublicCodesEnum
  focus: MatchmakingFocusCodesEnum
  specialty: MatchmakingSpecialtyCodesEnum | null
  currency: CurrenciesEnum
  sessionPrice: number
  mediaFolder: string
  whatsApp: string
  country: string
  clicks: number
}
