import { BaseResponse, MessageMethodsEnum, MessageTypesEnum } from '../..'
import { BaseRequest } from '.'

export type SendMessageRequest = BaseRequest & {
  method: MessageMethodsEnum
  type: MessageTypesEnum
  to: string
  language: string
}

export type SendMessageResponse = BaseResponse & {
  code?: string
}