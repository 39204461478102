import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule, CardModule, FormModule, GridModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { EventLogService, pageLinks } from '../../../../core';
import { UserService } from '../..'
import { ErrorsEnum } from 'src/libs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    GridModule,
    IconModule,
    FormModule,
    ReactiveFormsModule
  ]
})
export class LoginComponent implements OnInit {

  protected enableGoogle = !!environment.authWithGoogle;
  protected form: FormGroup;
  protected accountNotFoundError = false;
  protected wrongPasswordError = false;
  protected tooManyAttemptsError = false;
  protected notAllowedError = false;

  constructor(
    private userService: UserService,
    private eventLogService: EventLogService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.maxLength(320)]],
      password: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(72)]],
    });
  }

  protected async onLogInWithGoogle() {
    // this.languageService doesn't have currentLanguage, so let's hardcode it for now
    // window.location.href = `${environment.authWithGoogle}?lang=${this.languageService.currentLanguage}`;
    window.location.href = `${environment.authWithGoogle}?lang=pt-BR`;
  }

  protected async onLogIn() {
    this.clearErrors();
    try {
      await this.userService.logIn(this.form.value.email, this.form.value.password);
      this.eventLogService.insertSignInAttemptEvent(this.form.value.email);
      this.eventLogService.insertAuthenticatedEvent();
      await this.router.navigate([pageLinks.dashboard]);
    }
    catch (ex) {
      if (ex.code === ErrorsEnum.AUTH_USER_NOT_FOUND ) {
        this.accountNotFoundError = true;
        this.eventLogService.insertSignInAttemptEvent(this.form.value.email, ex.code );
      } else if (ex.code === ErrorsEnum.AUTH_WRONG_PASSWORD) {
        this.wrongPasswordError = true;
        this.eventLogService.insertSignInAttemptEvent(this.form.value.email, ex.code );
      } else if (ex.code === ErrorsEnum.AUTH_TOO_MANY_REQUESTS ) {
        this.tooManyAttemptsError = true;
        this.eventLogService.insertSignInAttemptEvent(this.form.value.email, ex.code );
      } else {
        this.notAllowedError = true;
        this.eventLogService.insertSignInAttemptEvent(this.form.value.email, ErrorsEnum.NOT_ALLOWED );
      }
    }
  }

  private clearErrors() {
    this.accountNotFoundError = false;
    this.wrongPasswordError = false;
    this.tooManyAttemptsError = false;
    this.notAllowedError = false;
  }
}
