import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  AuthClient,
  OpsAuthenticatedRequestTypesEnum,
  ProductsEnum,
  StatusEnum,
  GetUserViewRequest,
  GetUserViewResponse,
  AppUserView,
  ErrorsEnum,
  CogniTeamRolesEnum,
} from '../../../../libs';
import { CloudFunctionsService } from '../../../core';
import { AppUserProfile, UserIdentity, UserOptions, UserRoles } from '../types';
import { OpsUserViewFactory } from '../factories';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  #userIdentity: UserIdentity | undefined;
  #userOptions: UserOptions | undefined;
  #userRoles: UserRoles | undefined;

  private rolesSubject = new BehaviorSubject<CogniTeamRolesEnum[]>([]);
  public readonly roles$ = this.rolesSubject.asObservable();

  private firstNameSubject = new BehaviorSubject<string | undefined>(undefined);
  public readonly firstName$ = this.firstNameSubject.asObservable();

  public photoURL: string | null = null;

  constructor(private authClient: AuthClient,
    private functionsService: CloudFunctionsService
  ) {
  }

  async customTokenSignIn(customToken: string) {
    await this.authClient.customTokenSignIn(customToken);
    this.photoURL = this.authClient.photoURL;
    this.setInitialUserState();
    if (!this.isCogniTeamMember) {
      console.warn('not a Cogni member, logging out');
      await this.logOut();
      throw new Error(ErrorsEnum.NOT_ALLOWED, {cause: ErrorsEnum.NOT_ALLOWED });
    }
    await this.loadUserProfile();
  }

  async logIn(email: string, password: string) {
    await this.authClient.signIn(email, password);
    if (!this.isCogniTeamMember) {
      await this.logOut();
      throw new Error(ErrorsEnum.NOT_ALLOWED, {cause: ErrorsEnum.NOT_ALLOWED })
    }
    await this.loadUserProfile();
  }

  async logOut() {
    await this.authClient.signOut();
  }

  // NOTE: this is being called by app.component.ts through a signal being
  // listented there... it's very dificult to make sense of the code sequence.
  // I added an exception there so the #/action route was not redirected to
  // #/login, so I'm calling this from the code above (customTokenSignIn).
  // The regular signIn (logIn here... terminology is inconsistent) should
  // probably to the same, instead of being called from app.component
  // TODO: restructure this mess
  setInitialUserState() {
    this.#userIdentity = OpsUserViewFactory.createUserIdentity({
      uid: this.authClient.uid,
      email: this.authClient.email,
      displayName: this.authClient.displayName
    });
    this.firstNameSubject.next(this.#userIdentity.firstName);
    this.#userRoles = OpsUserViewFactory.createUserRoles({
      customClaims: this.authClient.customClaims
    });
    this.firstNameSubject.next(this.#userIdentity.firstName);
    this.rolesSubject.next(this.#userRoles.roles);
  }

  async loadUserProfile() {
    const request: GetUserViewRequest = {
      callerId: ProductsEnum.OPS
    };
    const result = await this.functionsService.opsAuthenticatedCall({
      type: OpsAuthenticatedRequestTypesEnum.GET_USER_VIEW,
      request
    });
    if (result.status === StatusEnum.OK) {
      const view = { ...(result as GetUserViewResponse).user };
      await this.rebuildProfileModel(view);
    }
    return this.userProfile;
  }

  clear() {
    this.#userIdentity = undefined;
    this.#userOptions = undefined;
    this.#userRoles = undefined;
  }

  private async rebuildProfileModel(view: AppUserView) {
    const userIdentity = OpsUserViewFactory.reconstituteUserIdentity({...view }); // TODO remove email from here
    const userOptions = OpsUserViewFactory.reconstituteUserOptions(view);
    await this.setUserIdentity(userIdentity);
    await this.setUserOptions(userOptions);
  }

  async reloadCurrentUser() {
    return this.authClient.reloadCurrentUser();
  }

  get userProfile(): AppUserProfile {
    return {
      identity: this.#userIdentity,
      options: this.#userOptions,
      roles: this.#userRoles
    };
  }

  get auth() {
    return this.authClient;
  }

  get uid() {
    return this.authClient.uid;
  }

  get roles() {
    return this.#userRoles.roles;
  }

  get isCogniTeamMember() {
    return this.#userRoles?.roles?.length > 0
  }

  get isDeveloper() {
    return this.#userRoles?.roles?.includes(CogniTeamRolesEnum.DEVELOPER);
  }

  get isAdmin() {
    return this.#userRoles?.roles?.includes(CogniTeamRolesEnum.ADMIN);
  }

  get isSupport() {
    return this.#userRoles?.roles?.includes(CogniTeamRolesEnum.SUPPORT);
  }

  private async setUserIdentity(newIdentity: UserIdentity | undefined) {
    this.#userIdentity = newIdentity;
    this.firstNameSubject.next(this.#userIdentity?.firstName);
  }

  private async setUserOptions(newOptions: UserOptions | undefined) {
    this.#userOptions = newOptions;
  }
}
