import {
  AccountsEnum,
  CancellationReasonsEnum,
  CollectionMethodsEnum,
  CurrenciesEnum,
  GatewaysEnum,
  PaymentStatusesEnum,
  ProductSubscriptionCodesEnum,
  SubscriberTypesEnum,
  SubscriptionFailuresEnum,
  SubscriptionRecurrenciesEnum,
  SubscriptionStatusesEnum,
  SubscriptionTypes
} from '../enums'
import { TaxPayerInformation } from '../types'

export type BillingSubscriptionDBDocument = {
  id: string //subscription Id
  uid: string
  account: AccountsEnum
  gateway: GatewaysEnum
  subscriberType: SubscriberTypesEnum
  subscriptionType: SubscriptionTypes
  plan: string
  product: ProductSubscriptionCodesEnum
  recurrence: SubscriptionRecurrenciesEnum
  currency: CurrenciesEnum | null
  payerCountry: string | null
  payerEmail: string | null
  collectionMethod: CollectionMethodsEnum
  accumulatedValueInCents: number
  paymentCycles: number
  planPriceInCents: number
  installmentPriceInCents: number | null
  pendingInstallments: number | null
  subscriptionStatus: SubscriptionStatusesEnum
  paymentStatus: PaymentStatusesEnum
  subscriptionFailure: SubscriptionFailuresEnum | null
  subscribedAtDate: Date
  lastPaymentAtDate: Date | null
  paymentDueAtDate: Date | null
  cancelledAtDate: Date | null
  expiresAtDate: Date | null
  terminatedAtDate: Date | null
  cancellationReason: CancellationReasonsEnum | null
  taxPayerInformation: TaxPayerInformation | null
  pendingAudit: boolean | null
  pendingPayerInformation?: boolean
  pendingESInvoce?: boolean
  notes?: string | null
}

export type StripeBillingSubscriptionDBDocument = BillingSubscriptionDBDocument & {
  stripeCustomerId: string | null
  stripeProduct: string
  stripeNextPaymentAttemptAtDate: Date | null
  stripePaymentLink: string | null
}

export type StripeCustomerDBDocument = {
  customerId: string
  email: string
  phone: string | null
  name: string | null
  address: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  country: string | null
  preferredLocales: string[] | null
  uid: string | null
  createdAtDate: Date
  invoicePrefix?: string | null
}
