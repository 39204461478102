import { ErrorsEnum, StatusEnum } from '..'

export type ErrorDescription = {
  error?: ErrorsEnum
  message?: string
  params?: string[]
}

export type Error = {
  errorDescription: ErrorDescription
}

export type BaseResponse = ErrorDescription & {
  status: StatusEnum
}
