import { Locale } from 'date-fns';

export type SupportedLocale = {
  dateFns: Locale;
  calendarDateFormat: string;
  shortDateFormat: string;
  longDateFormat: string;
  timeFormat: string;
  datetimeFormat: string;
  dayNamesForCalendar: string[];
  monthNames: string[];
  monthShortNames: string[];
  onlyNumbersDateFormat: string;
  onlyNumbersDatePlaceholder: string[];
  onlyNumbersDateCaption: string[];
};
