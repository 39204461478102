import { environment } from '../../../environments/environment';

export class ConfigService {

  private static environmentConfig = environment;

  static getProductsEnum() {
    return ConfigService.environmentConfig.domainConfigs.productsEnum;
  }

  static getConfig(id: string): any {
    return ConfigService.environmentConfig[id];
  }

  static getFirebase() {
    return ConfigService.environmentConfig.firebase;
  }

  static isProduction(): boolean {
    return ConfigService.environmentConfig.production;
  }
}
