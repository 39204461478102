import { BaseResponse, AppUserView, DashUserView, OpsUserView } from '../..'
import { BaseRequest } from '.'

export type GetAppUserViewRequest = BaseRequest
export type GetDashUserViewRequest = BaseRequest
export type GetOpsUserViewRequest = BaseRequest

export type GetAppUserViewResponse = BaseResponse & {
  user?: AppUserView
}

export type GetDashUserViewResponse = BaseResponse & {
  user: DashUserView
}

export type GetOpsUserViewResponse = BaseResponse & {
  user?: OpsUserView
}

export type GetUserViewRequest = GetAppUserViewRequest | GetDashUserViewRequest | GetOpsUserViewRequest
export type GetUserViewResponse = GetAppUserViewResponse | GetDashUserViewResponse | GetOpsUserViewResponse
