import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibCoreuiC,

  cilAddressBook,
  cilApplicationsSettings,
  cilBell,
  cilBurn,
  cilCheck,
  cilClipboard,
  cilColumns,
  cilCommentSquare,
  cilCreditCard,
  cilEnvelopeOpen,
  cilList,
  cilLockLocked,
  cilMenu,
  cilMoney,
  cilPeople,
  cilSearch,
  cilSettings,
  cilSpeedometer,
  cilSpreadsheet,
  cilTask,
  cilUser,
  cilX,

} from '@coreui/icons';

export const iconSubset = {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibCoreuiC,
  cilColumns,

  cilAddressBook,
  cilApplicationsSettings,
  cilBell,
  cilBurn,
  cilCheck,
  cilClipboard,
  cilCommentSquare,
  cilCreditCard,
  cilEnvelopeOpen,
  cilList,
  cilLockLocked,
  cilMenu,
  cilMoney,
  cilPeople,
  cilSearch,
  cilSettings,
  cilSpeedometer,
  cilSpreadsheet,
  cilTask,
  cilUser,
  cilX,
};

export enum IconSubset {
  cibCcAmex = 'cibCcAmex',
  cibCcApplePay = 'cibCcApplePay',
  cibCcMastercard = 'cibCcMastercard',
  cibCcPaypal = 'cibCcPaypal',
  cibCcStripe = 'cibCcStripe',
  cibCcVisa = 'cibCcVisa',
  cibCoreuiC = 'cibCoreuiC',

  cilAddressBook = 'cilAddressBook',
  cilApplicationsSettings = 'cilApplicationsSettings',
  cilBurn = 'cilBurn',
  cilBell = 'cilBell',
  cilCheck = 'cilCheck',
  cilClipboard = 'cilClipboard',
  cilColumns = 'cilColumns',
  cilCommentSquare = 'cilCommentSquare',
  cilCreditCard = 'cilCreditCard',
  cilEnvelopeOpen = 'cilEnvelopeOpen',
  cilList = 'cilList',
  cilLockLocked = 'cilLockLocked',
  cilMenu = 'cilMenu',
  cilMoney = 'cilMoney',
  cilPeople = 'cilPeople',
  cilSearch = 'cilSearch',
  cilSettings = 'cilSettings',
  cilSpeedometer = 'cilSpeedometer',
  cilSpreadsheet = 'cilSpreadsheet',
  cilTask = 'cilTask',
  cilUser = 'cilUser',
  cilX = 'cilX'
}
