import { GenderCodesEnum } from '../..'
import { ProfessionalIdTypesEnum, ProfessionCodesEnum, TherapistStatusesEnum } from '..'

export type TherapistDBDocument = {
  cognicode: string
  createdAtDate: Date
  updatedAtDate: Date
  therapistId: string,
  patientId: string,
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  professionalId: string | null
  professionalIdType: ProfessionalIdTypesEnum | null
  profession: ProfessionCodesEnum
  status: TherapistStatusesEnum
  interconnectionId: string | null
}