import { LanguageCodesEnum, OperatingSystemsEnum } from '../../../libs'
import {
  AppNotificationTypesEnum,
  PushNotificationDiscardReasonsEnum,
  PushNotificationStatuses,
  PushPermissionStatusesEnum,
  ReattemptFrequenciesEnum
} from '../enums'

export type PushNotificationsConfigurationDBDocument = {
  readonly status: PushPermissionStatusesEnum
  readonly uid: string
  readonly deviceUuid: string
  readonly language: LanguageCodesEnum
  readonly os: OperatingSystemsEnum
  readonly osVersion: string
  readonly appVersion: string
  readonly updatedAtDate: Date
  readonly token?: string
  // The time-zone offset is the difference, in minutes, between UTC and local time
  // The offset is positive if the local timezone is behind UTC and negative if it is ahead
  // e.g.: if time zone is UTC+10 (Australian Eastern Standard Time), -600 will be returned
  readonly timezoneOffsetInMinutes: number
  readonly deniedAtDate?: Date
  readonly reattemptFrequency?: ReattemptFrequenciesEnum
  readonly reattemptAtDate?: Date
}

export type BasePushData = {
  readonly uid: string
  readonly notificationId: string
  readonly type: AppNotificationTypesEnum
  readonly link?: string
}

export type IdentifiedPushData = BasePushData & {
  readonly itemId: string // record id, therapist id, module id, scale id ...
}

export type PushData = BasePushData | IdentifiedPushData

export type AppPushNotificationSentDBDocument = PushData & {
  readonly status: PushNotificationStatuses
  readonly fcmMessageId: string
  readonly sentAtDate: Date
}

export type AppPushNotificationActionPerformeddStatusUpdate = {
  readonly status: PushNotificationStatuses
  readonly actionPerformedAtDate: Date
}

export type AppPushNotificationDiscardedStatusUpdate = {
  readonly status: PushNotificationStatuses
  readonly discardedAtDate: Date
  readonly discardReason: PushNotificationDiscardReasonsEnum
}

export type AppPushNotificationDBDocument = AppPushNotificationSentDBDocument

export type AppPushNotificationStatusUpdate = AppPushNotificationActionPerformeddStatusUpdate
  | AppPushNotificationDiscardedStatusUpdate
