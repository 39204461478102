import {
  GenderCodesEnum
} from '../..'

import {
  ArchiveReasonsEnum,
  PatientNotificationTypesEnum,
  PatientStatusesEnum,
} from '..'

export type PatientDBDocument = {
  createdAtDate: Date
  updatedAtDate: Date
  therapistId: string
  patientId: string
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  status: PatientStatusesEnum
  archiveReason: ArchiveReasonsEnum | null
  interconnectionId: string | null
  lastDTREditionAtDate: Date | null
  notifications: PatientNotificationTypesEnum[] | null
  updatedDTRsIds: string[] | null
}
